export const FINANCE_UPDATE_URL_TYPES = {
  FINANCE_RESULT: "FINANCE_RESULT",
  FINANCE_HIPAY_FAIL: "FINANCE_HIPAY_FAIL",
  FINANCE_STRIPE_FAIL: "FINANCE_STRIPE_FAIL",
};

export const financeResultUpdateUrlEvent = data => ({
  type: FINANCE_UPDATE_URL_TYPES.FINANCE_RESULT,
  data,
});

export const financeHipayFailUpdateUrlEvent = data => ({
  type: FINANCE_UPDATE_URL_TYPES.FINANCE_HIPAY_FAIL,
  data,
});

export const financeStripeFailUpdateUrlEvent = data => ({
  type: FINANCE_UPDATE_URL_TYPES.FINANCE_STRIPE_FAIL,
  data,
});
