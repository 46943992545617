const cookie = require("cookie");
const HttpBackend = require("i18next-http-backend/cjs");
const path = require("path");

const {
  IS_SERVER,
  KOA_LANGUAGE_COOKIE_ID,
  LOCALES,
  REMOTE_LOCALES,
} = require("@shared/constants");

const serverDetector = {
  name: "server-detector",
  lookup(req) {
    if (!IS_SERVER || !req.session?.language) {
      return null;
    }

    return req.session.language;
  },
};

const browserDetector = {
  name: "browser-detector",
  lookup() {
    if (IS_SERVER) {
      return null;
    }

    return cookie.parse(window.document.cookie)[KOA_LANGUAGE_COOKIE_ID];
  },
};

/**
 *
 * @param {string} market Environment market, e.g. 'FR'. Take it from .ENV
 * @param {Object} remoteConfig Remote translations config
 * @param {boolean} remoteConfig.enabled Remote translations enabled/disabled
 * @param {string} remoteConfig.url Remote translations URL
 * @param {interval} remoteConfig.refreshInterval Remote translations refresh interval
 */
function getNextI18NextConfig(market, remoteConfig) {
  const commonProps = {
    ns: "common",
    defaultNS: "common",
    serverLanguageDetection: true,
    browserLanguageDetection: true,
    customDetectors: [serverDetector, browserDetector],
    detection: {
      order: ["server-detector", "browser-detector"],
    },
  };

  if (!remoteConfig.enabled) {
    return {
      ...commonProps,
      defaultLanguage: LOCALES.FR,
      otherLanguages: Object.values(LOCALES),
      localePath: IS_SERVER
        ? path.resolve("./frontend/public/locales")
        : "/ami/locales",
    };
  }

  return {
    ...commonProps,
    defaultLanguage: REMOTE_LOCALES[market]?.[0] || LOCALES.EN,
    otherLanguages: REMOTE_LOCALES[market] || [],

    use: [HttpBackend],
    backend: {
      serializeConfig: true,
      loadPath: function(lngs) {
        const urlParts = [
          remoteConfig.url,
          "translations",
          market.toLowerCase(),
          lngs[0].substr(0, 2),
          `{{ns}}`,
        ];

        return urlParts.join("/");
      },
      reloadInterval: parseInt(remoteConfig.refreshInterval || 900_000, 10),
      crossDomain: true,
    },
  };
}

module.exports = {
  getNextI18NextConfig,
};
