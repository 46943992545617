import { LOCALES } from "@shared/constants";

import {
  shape,
  string,
  bool,
  arrayOf,
  array,
  number,
  oneOf,
  oneOfType,
  exact,
  object,
} from "prop-types";

export const technicalParameterType = shape({
  label: string,
  description: string,
});

export const deliveryPointType = shape({
  id: number.isRequired,
  name: string.isRequired,
  description: string,
  street: string,
  streetNumber: string,
  postcode: string.isRequired,
  city: string,
  county: string,
  addressCountry: string.isRequired,
  phone: string.isRequired,
  geoLatitude: number.isRequired,
  geoLongitude: number.isRequired,
  type: string.isRequired,
  status: bool,
  country: string.isRequired,
  _id: string,
  distance: number,
});

export const testDriveLocationType = shape({
  id: number.isRequired,
  name: string.isRequired,
  address1: string.isRequired,
  address2: string,
  zipcode: string,
  city: string,
  email: string,
  geoLatitude: number.isRequired,
  geoLongitude: number.isRequired,
  type: string.isRequired,
  status: bool,
  country: string.isRequired,
  countryName: string.isRequired,
  _id: string,
  distance: number,
  code: string,
  language: string,
});

export const homeTestDriveLocationType = shape({
  id: number.isRequired,
  name: string.isRequired,
  address: string.isRequired,
  zipcode: string.isRequired,
  city: string,
  country: string.isRequired,
  brandId: string.isRequired,
  concessionIdVC: string.isRequired,
  geoLatitude: number.isRequired,
  geoLongitude: number.isRequired,
});

export const testDriveType = exact({
  _id: string,
  brand: string,
  language: string,
  country: string,
  id: string,
  label: string,
  description: string,
  additionalDescription: string,
  colorCode: string,
  colorHexa: string,
  order: number,
  status: bool,
});

export const testDriveVehicleType = shape({
  id: string.isRequired,
  code: string.isRequired,
  country: string.isRequired,
  geoLatitude: number.isRequired,
  geoLongitude: number.isRequired,
  _id: string.isRequired,
});

export const mapMarkerType = shape({
  id: string.isRequired,
  lat: number.isRequired,
  lng: number.isRequired,
  type: string.isRequired,
  storeType: string,
});

export const personalSummaryType = shape({
  title: string,
  first_name: string,
  last_name: string,
  phone: string,
  email: string,
  tc_accepted: bool,
});

export const deliverySummaryType = shape({
  name: string,
  address_line_1: string,
  address_line_2: string,
  city: string,
  postcode: string,
});

export const personalizeOptionType = shape({
  id: string.isRequired,
  label: string.isRequired,
  image: string,
});

export const colorCodeType = shape({
  color: string.isRequired,
  label: string.isRequired,
});

export const optionType = shape({
  id: string.isRequired,
  label: string.isRequired,
  description: string.isRequired,
  netPrice: number.isRequired,
  tax: number.isRequired,
  type: string,
  image: string,
  personalizeOptions: arrayOf(personalizeOptionType),
  colorCodeDetails: arrayOf(colorCodeType),
});

const simulationPriceType = shape({
  cash: shape({
    basePrice: number.isRequired,
    netPrice: number.isRequired,
    tax: number.isRequired,
    promo: number,
  }),
});

export const financeWidgetParametersType = shape({
  annualMileage: number,
  deposit: number,
  depositAmountKind: string,
  depositAmountNature: string,
  duration: number,
  productKey: string,
});

export const promoCodeType = shape({
  code: string,
  discountAmount: number,
  discountPercentage: number,
  discountType: oneOf(["absolute", "percentage_catalog"]),
  endDate: string,
  id: number,
  journeyType: oneOf(["ALL", "CASH", "FINANCE"]),
  label: string,
  valid: bool,
});

export const financeSimulationType = shape({
  defaultPrice: simulationPriceType,
  vehiclePrice: simulationPriceType,
  totalPrice: simulationPriceType,
  deliveryPrice: simulationPriceType,
  paymentType: oneOfType([number, string]),
  journey: oneOfType([number, string]),
  monthlyTotalPrice: oneOfType([number, string]),
  monthlyTotalPricePreconfiguration: number,
  vehicleRegistration: string,
  financeWidgetParameters: financeWidgetParametersType,
  promoCode: oneOfType([promoCodeType, bool]),
});

export const financeServicesType = shape({
  cdOptEki: string,
  cdCatSvc: string,
  label: string,
  selected: bool,
  rental: shape({
    amountExclTax: number,
    amountInclTax: number,
  }),
  total: shape({
    amountExclTax: number,
    amountInclTax: number,
  }),
});

export const deliveryOptionType = shape({
  id: string,
  label: string,
  shortDescription: string,
  longDescription: string,
  type: string,
  basePrice: number,
  tax: number,
  netPrice: number,
  status: oneOf([1, 0]),
  country: string,
  language: string,
});

export const userProfileType = shape({
  customerId: string,
  email: string,
  userFirstName: string,
  userLastName: string,
  phone: string,
  civility: string,
  deliveryAddress1: string,
  deliveryCity: string,
  deliveryPostCode: string,
  deliveryCountry: string,
  billingAdress1: string,
  billingCity: string,
  billingPostCode: string,
  billingCountry: string,
  registrationAddress1: string,
  registrationCity: string,
  registrationPostalCode: string,
  registrationCountry: string,
  deliveryData: deliveryOptionType,
  address1: string,
  city: string,
  postCode: string,
});

export const packageType = shape({
  id: string,
  label: string,
  netPrice: number,
  image: string,
});

export const addressType = shape({
  id: string,
  street: string,
  streetNumber: string,
  city: string,
  country: string,
  postcode: string,
  geoLat: number,
  geoLng: number,
});

export const simpleAddressType = shape({
  address1: string,
  address2: string,
  city: string,
  postCode: string,
  country: string,
});

export const personalDetail = exact({
  phone: string,
  email: string,
  password: string,
  civility: string,
  tin: string,
  userFirstName: string,
  userLastName: string,
});

export const companyDetail = exact({
  companyName: string,
  siretNumber: string,
  TVANumber: string,
});

export const preferredContacts = exact({
  email: bool.isRequired,
  phone: bool.isRequired,
  sms: bool.isRequired,
});

export const transformedPersonalDetail = shape({
  civility: string,
  userFirstName: string,
  userLastName: string,
  phone: string,
});

export const sizeType = oneOf([
  "xs",
  "sm",
  "default",
  "md",
  "lg",
  "xl",
  "xxl",
  "xxxl",
  "giant",
]);

export const languageType = oneOf(Object.values(LOCALES));

export const latLngType = exact({
  lat: number,
  lng: number,
});

export const carConfigurationType = shape({
  country: string,
  modelId: string,
  length: number,
  idVersion: string,
  description: string,
  netPrice: number,
  tax: number,
  language: string,
  label: string,
  services: arrayOf(object),
  modelLabel: string,
  technicalCharacteristics: arrayOf(object),
  preconfigurations: arrayOf(object),
  width: number,
  options: arrayOf(object),
  basePrice: number,
  status: string,
  height: number,
  _id: string,
});

export const vehicleSimulationType = shape({
  duration: number,
  annualMileage: number,
  globalMileage: number,
  financialProductLabel: string,
  financialType: string,
  regularPayment: shape({
    amountExclTax: number,
    amountInclTax: number,
  }),
  firstRental: shape({
    amountExclTax: number,
    amountInclTax: number,
  }),
  financialFirstRental: shape({
    amountExclTax: number,
    amountInclTax: number,
  }),
  totalAmount: shape({
    amountExclTax: number,
    amountInclTax: number,
  }),
});

export const dealType = shape({
  offerExpirationDate: string,
  externalOrderId: string,
  token: string,
  source: string,
  country: string,
  brandCode: string,
  vehicle: object,
  customer: shape({
    first_name: string,
    last_name: string,
    email: string,
  }),
  userProfile: shape({
    email: string,
    firstname: string,
    lastname: string,
    phone: string,
  }),
  carConfiguration: carConfigurationType,
  dealer: oneOfType([object, string]),
  deliveryDealer: oneOfType([object, array]),
  payment: oneOfType([object, string]),
  paymentFinance: string,
  status: string,
  created_at: number,
  updated_at: number,
  offerCreated: number,
  gitId: string,
});

export const dealItemType = shape({
  isPriceLocked: bool,
  token: string,
  order: string,
  dealId: string,
  brandCode: string,
  externalId: string,
  dealState: string,
  status: string,
  country: string,
  externalOrderId: string,
  language: string,
  prescoreStatus: string,
  userProfile: userProfileType,
  carConfiguration: carConfigurationType,
  financeServices: arrayOf(financeServicesType),
  financeSimulation: financeSimulationType,
  vehicleSimulation: vehicleSimulationType,
  deliveryDealer: oneOfType([deliveryPointType, shape({})]),
  deliverySummary: deliverySummaryType,
  personalSummary: personalSummaryType,
  currentTimelineStep: string,
  options: arrayOf(optionType),
  technicalCharacteristics: arrayOf(technicalParameterType),
  salesman: string,
  isSelectionConfirmed: bool,
  isSavedByUser: bool,
  isEcoBonusEnabled: bool,
  lastCta: string,
  currentDeal: dealType,
  offerCreated: number,
  gitId: string,
  dealExpired: bool,
  previousRoute: string,
  isRefreshingDeal: bool,
  _persist: shape({
    version: number,
    rehydrated: bool,
  }),
});

export const routerType = shape({
  sdc: object,
  isFallback: bool.isRequired,
  route: string.isRequired,
  pathname: string.isRequired,
  asPath: string.isRequired,
  query: shape({
    lang: string,
  }),
});

export const financingCashData = exact({
  preconfigurationLabel: string,
  deliveryPrice: number,
  options: arrayOf(object),
  accessories: arrayOf(object),
  services: arrayOf(object),
  basePrice: number,
  totalPrice: number,
  totalPriceWithoutTax: number,
  isDeliveryIncluded: bool,
  packPrice: object,
  registrationDiscountAmount: number,
  govDiscountPrice: number,
  productPromoPrice: number,
});

export const financingLLDData = exact({
  totalPrice: number,
  deposit: number,
  duration: number,
  annualMileage: number,
  isDeliveryIncluded: bool,
  areServicesIncluded: bool,
  displayBlocks: arrayOf(object),
  legalMention: arrayOf(object),
  financialProductLabel: string,
  regularPayment: shape({
    amountExclTax: number,
    amountInclTax: number,
  }),
  firstRental: object,
});

export const financingLOAData = exact({
  deposit: number,
  duration: number,
  annualMileage: number,
  isDeliveryIncluded: bool,
  areServicesIncluded: bool,
  displayBlocks: arrayOf(object),
  legalMention: arrayOf(object),
  financialProductLabel: string,
  regularPayment: shape({
    amountExclTax: number,
    amountInclTax: number,
  }),
  firstRental: object,
});

export const financingVACData = exact({
  deposit: number,
  duration: number,
  annualMileage: number,
  isDeliveryIncluded: bool,
  areServicesIncluded: bool,
  displayBlocks: arrayOf(object),
  legalMention: arrayOf(object),
  financialProductLabel: string,
  regularPayment: shape({
    amountExclTax: number,
    amountInclTax: number,
  }),
  firstRental: object,
});

export const financingSCFData = exact({
  deposit: number,
  duration: number,
  annualMileage: number,
  isDeliveryIncluded: bool,
  areServicesIncluded: bool,
  displayBlocks: arrayOf(object),
  legalMention: arrayOf(object),
  financialProductLabel: string,
  regularPayment: shape({
    amountExclTax: number,
    amountInclTax: number,
  }),
  firstRental: object,
});

export const orderType = shape({
  appBaseUrl: string,
  brandCode: string,
  configuration: object,
  country: string,
  created_at: number,
  ctaActionsHistory: arrayOf(shape({ created_at: number, name: string })),
  currency: string,
  customer: shape({
    email: string,
    first_name: string,
    last_name: string,
    phone: string,
    type: null,
    honda_owner: null,
  }),
  dealExpired: bool,
  dealOrigin: string,
  dealState: string,
  dealType: string,
  dealer: shape({
    RCSNumber: string,
    RRDICode: string,
    TVANumber: string,
    addressLine2: null,
    address_line_2: null,
    capital: string,
    city: string,
    country: string,
    customerServiceEmail: string,
    email: string,
    geoPoint: string,
    legalForm: string,
    postcode: string,
    siretNumber: string,
    siteGeo: string,
    tradeName: string,
    zipCode: string,
  }),
  deliveryDealer: shape({
    RRDICode: string,
    _id: string,
    address1: string,
    address2: null,
    addressCountry: string,
    country: string,
    county: null,
    description: string,
    distance: number,
    email: string,
    geoLatitude: number,
    geoLongitude: number,
    id: number,
    latitude: number,
    longitude: number,
    markerIcon: string,
    name: string,
    phone: string,
    phoneNumber: string,
    postcode: string,
    salesmanId: string,
    siteGeo: string,
    status: true,
    town: string,
    type: string,
    zipCode: string,
  }),
  deliveryMethod: shape({
    basePrice: null,
    country: string,
    id: string,
    label: string,
    language: null,
    netPrice: number,
    tax: null,
    type: string,
  }),
  emails: shape({
    O2CCashOrder: string,
  }),
  extendedCustomerInfo: object,
  extendedVehicleInfo: object,
  externalId: string,
  externalOrderId: string,
  financeServices: array,
  financeSimulation: financeSimulationType,
  gitId: string,
  isEcoBonusEnabled: bool,
  isPriceLocked: bool,
  isSavedByUser: bool,
  isScrappageSelected: bool,
  language: string,
  offerCreatedAt: number,
  offerExpiresAt: number,
  offlinePayment: shape({
    amount: number,
    maxPaymentDate: string,
    psaPayId: string,
    reference: string,
    status: string,
    type: string,
  }),
  payment: object,
  status: string,
  testDrive: shape({
    date: string,
    isoDate: string,
    point: null,
    slot: null,
    time: string,
    types: array,
  }),
  token: string,
  updated_at: number,
  vehicle: shape({ model: string }),
  vehicleSimulation: vehicleSimulationType,
});

export const financeGatewayTeaserType = shape({
  callToAction: shape({
    detailText: string,
    text: string,
    useDetail: bool,
    useWidget: bool,
  }),
  data: shape({
    annualMileage: number,
    apr: number,
    duration: number,
    financialProductLabel: string,
    financialType: string,
    firstRental: shape({
      amountInclTax: number,
    }),
    regularPayment: shape({
      amountInclTax: number,
    }),
    tin: number,
  }),
  guide: shape({
    amountInclExclTax: arrayOf(string),
    amount: array,
    percentages: arrayOf(string),
    shownTextCollections: arrayOf(string),
    shownTexts: arrayOf(string),
  }),
  primaryText: arrayOf(
    shape({
      arguments: arrayOf(string),
      style: shape({
        displaySize: number,
      }),
      text: string,
    })
  ),
  regularPayment: shape({
    arguments: arrayOf(string),
    text: string,
  }),
  textConditions: shape({
    primaryDepositConditions: array,
    widgetPrimaryText: arrayOf(
      shape({
        text: string,
      })
    ),
  }),
});
