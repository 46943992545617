const formatNumber = require("number-format.js");

const NON_BREAKING_SPACE = "\u00A0";

const getFormattedPrice = (amount, format, currencySymbol = "") => {
  const regex = /#.#*(?=[^#]*$)/;
  const replacer = "0";

  const modifiedFormat = format.replace(regex, match => {
    if (Number.isInteger(amount)) {
      return match.replace("#", replacer);
    }

    return match.replace(/#/g, replacer);
  });

  const formatWithProperCurrency = currencySymbol
    ? modifiedFormat.replace("$", currencySymbol)
    : modifiedFormat;

  return formatNumber(formatWithProperCurrency, isNaN(amount) ? 0 : amount)?.replace(
    /\s/g,
    NON_BREAKING_SPACE
  );
};

module.exports = { getFormattedPrice };
