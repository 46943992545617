import produce from "immer";

import { i18n } from "@lib/i18n";
import { getLanguageCode } from "@shared/v2/lib/getLanguageCode";
import { FINANCE_UPDATE_URL_TYPES } from "@gtm/events/finance";
import { buildPageName } from "../core/utils";

import {
  DEFAULT_UPDATE_URL_DATA_LAYER_OBJECT,
  PAGE_CATEGORY_TRANSACTION,
  SITE_TARGET_B2C,
  SITE_TARGET_B2B,
} from "@gtm/constants";

export const financeReducerUpdateUrl = ({ type, data }) =>
  produce(DEFAULT_UPDATE_URL_DATA_LAYER_OBJECT, draft => {
    switch (type) {
      case FINANCE_UPDATE_URL_TYPES.FINANCE_RESULT:
        draft.virtualPageURL = `/buy/payment/finance/${data.status}`;
        draft.pageCategory = PAGE_CATEGORY_TRANSACTION;
        draft.mainStepIndicator = "9";
        draft.mainStepName = "psabank success";
        draft.pageVariant = data.pageVariant;
        draft.affiliation = data.affiliation;
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
        });
        break;

      case FINANCE_UPDATE_URL_TYPES.FINANCE_HIPAY_FAIL:
        draft.virtualPageURL = "/buy/payment/failure";
        draft.pageCategory = PAGE_CATEGORY_TRANSACTION;
        draft.mainStepIndicator = "9";
        draft.mainStepName = "hipay failed";
        draft.pageVariant = data.pageVariant;
        draft.affiliation = data.affiliation;
        draft.siteTarget = data.isB2BSelected
          ? SITE_TARGET_B2B
          : SITE_TARGET_B2C;
        draft.pageName = buildPageName({
          pageTitle: data.pageTitle,
          businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
        });
        break;

        case FINANCE_UPDATE_URL_TYPES.FINANCE_STRIPE_FAIL:
          draft.virtualPageURL = "/buy/payment/failure";
          draft.pageCategory = PAGE_CATEGORY_TRANSACTION;
          draft.mainStepIndicator = "9";
          draft.mainStepName = "stripe failed";
          draft.pageVariant = data.pageVariant;
          draft.affiliation = data.affiliation;
          draft.siteTarget = data.isB2BSelected
            ? SITE_TARGET_B2B
            : SITE_TARGET_B2C;
          draft.pageName = buildPageName({
            pageTitle: data.pageTitle,
            businessModel: data.isB2BSelected ? SITE_TARGET_B2B : SITE_TARGET_B2C,
          });
          break;

      default:
        return null;
    }

    draft.language = getLanguageCode(i18n.language);
  });
