const debug = require("debug")("app:i18n");
const getConfig = require("next/config").default;
const NextI18Next = require("next-i18next").default;

const { getNextI18NextConfig } = require("./getI18NextConfig");

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig();
const { INTERNAL_DEAL_API_URL } = serverRuntimeConfig || {};
const {
  COUNTRY,
  DEAL_API_URL,
  I18NEXT_OVER_HTTP_ENABLED,
  I18NEXT_REFRESH_INTERVAL,
} = publicRuntimeConfig;

const config = getNextI18NextConfig(COUNTRY, {
  enabled: I18NEXT_OVER_HTTP_ENABLED,
  refreshInterval: I18NEXT_REFRESH_INTERVAL,
  url: INTERNAL_DEAL_API_URL || DEAL_API_URL,
});

debug(`ℹ️  Starting NextI18Next with config:`, config);
debug(
  `💬  If app gets stuck here or a few log lines later, or infinitely loads in browser, check NextI18Next configuration`
);

const nextI18Next = new NextI18Next(config);

module.exports = nextI18Next;
