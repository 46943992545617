const { produce } = require("immer");

const getPriceAlgorithm = require("../../PriceAlgorithm/getPriceAlgorithm");
const squashPreconfigurationOptions = require("../../helper/squashPreconfigurationOptions");
const { getIsLOAJourneySelected } = require("./getIsLOAJourneySelected");
const { getIsLLDJourneySelected } = require("./getIsLLDJourneySelected");
const { getIsVACJourneySelected } = require("./getIsVACJourneySelected");
const { getIsB2BSelected } = require("./getIsB2BSelected");
const { getLanguageCode } = require("./getLanguageCode");

const {
  CUSTOMIZE_TYPES,
  FG_DELIVERY_METHODS_PREFIX,
} = require("../../constants");

const {
  getSolJourneyConfigForJourney,
} = require("./getSolJourneyConfigForJourney");

const { COUNTRIES } = require("../../constants");
const { getEcoBonus } = require("./getEcoBonus");

// todo: maybe move to config?
const UK_REGISTRATION_FEES = 55;

const getFiscalProperties = (country, reduxDeal, appConfiguration) => {
  const solJourneyConfig = appConfiguration.configuration.app.solJourney;
  const priceAlgorithm = getPriceAlgorithm(country);

  // Select combinedOptions if available if not, use classic options list
  const combinedOptions = reduxDeal.carConfiguration.combinedOptions;
  const options = reduxDeal.carConfiguration.options;
  const packNetPrice = (combinedOptions && combinedOptions.length
    ? combinedOptions
    : options
  )
    .filter(option => option.type !== CUSTOMIZE_TYPES.ACCESSORY)
    .reduce((acc, cur) => priceAlgorithm.add([acc, cur.netPrice]), 0);

  return produce({}, draft => {
    const {
      financeSimulation: { journey, promoCode },
      businessModel,
    } = reduxDeal;

    const promoCodeNetAmount = promoCode?.discountAmount || 0;

    const { scrappage: scrappageConfiguration } = getSolJourneyConfigForJourney(
      journey,
      businessModel,
      appConfiguration.configuration
    );

    const ecoBonus = getEcoBonus(journey, appConfiguration, appConfiguration);
    if (ecoBonus.enabled) {
      draft.ecoBonus = priceAlgorithm.getDiscountAmount(
        [
          reduxDeal.carConfiguration.netPrice,
          packNetPrice,
          -promoCodeNetAmount,
        ],
        solJourneyConfig.ecoBonus.governmentDiscountPercentage,
        solJourneyConfig.taxPercentage
      );
    }

    if (
      scrappageConfiguration &&
      scrappageConfiguration.enabled &&
      reduxDeal.isScrappageSelected
    ) {
      draft.scrappageIncentive = priceAlgorithm.getDiscountAmount(
        [
          reduxDeal.carConfiguration.netPrice,
          packNetPrice,
          -promoCodeNetAmount,
        ],
        scrappageConfiguration.discountPercentage,
        solJourneyConfig.taxPercentage
      );
    }

    // Add registration fees for UK
    if (country === COUNTRIES.GB) {
      draft.registrationFees = UK_REGISTRATION_FEES;
    }
  });
};

const getAccessories = (deal, appData, isCombinedOptions) => {
  const {
    carConfiguration: { preconfiguration, accessory },
  } = deal;

  const accessoriesTransformed = accessory.map(accessory => ({
    key: accessory.id,
    pricing: {
      amountInclTax: accessory.netPrice,
    },
  }));

  const optionsSquashed = squashPreconfigurationOptions(
    preconfiguration.options,
    appData.car.options,
    preconfiguration.id
  );

  if (isCombinedOptions) return accessoriesTransformed;
  return [...accessoriesTransformed, ...optionsSquashed];
};

const getFinanceJourneyConfig = (
  isLOAJourneySelected,
  isVACJourneySelected,
  isB2BSelected,
  solJourneyConfig
) => {
  const solJourneyBusinessModelConfig = isB2BSelected
    ? solJourneyConfig.b2b
    : solJourneyConfig;

  if (isLOAJourneySelected) {
    return solJourneyBusinessModelConfig.loaJourney;
  }

  if (isVACJourneySelected) {
    return solJourneyBusinessModelConfig.vacJourney;
  }

  return solJourneyBusinessModelConfig.lldJourney;
};

const getFinanceGatewayContext = (
  country,
  language,
  reduxDeal,
  appData,
  options = {
    isDeliveryIncluded: true,
    withAccessories: false,
    withoutPromoCode: false,
  }
) => {
  const { isDeliveryIncluded, withAccessories, withoutPromoCode } = options;
  const solJourneyConfig = appData.configuration.app.solJourney;
  const journey = reduxDeal.financeSimulation.journey;
  const businessModel = reduxDeal.businessModel;

  const isLOAJourneySelected = getIsLOAJourneySelected(journey);
  const isLLDJourneySelected = getIsLLDJourneySelected(journey);
  const isVACJourneySelected = getIsVACJourneySelected(journey);
  const isB2BSelected = getIsB2BSelected(businessModel);

  const journeyConfig = getFinanceJourneyConfig(
    isLOAJourneySelected,
    isVACJourneySelected,
    isB2BSelected,
    solJourneyConfig
  );

  const widgetParameters = reduxDeal.financeSimulation.financeWidgetParameters;

  const deliveryData = reduxDeal.userProfile.deliveryData;
  const isDeliverySelected = deliveryData.id;

  const { combinedOptions } = reduxDeal.carConfiguration.preconfiguration;

  const isGB = country === COUNTRIES.GB;

  // Deduction is later excluded from net price, at the time of adding this
  // comment it is only used for removing UK registration fees from net price
  let deduction = 0;

  // Exclude delivery price and registration fees for UK from net price
  if (isGB) {
    deduction += UK_REGISTRATION_FEES;
  }

  // Promo Code Discount
  const { promoCode } = reduxDeal.financeSimulation;
  let promoCodeDiscount = 0;

  if (
    !withoutPromoCode &&
    promoCode?.valid &&
    new Date(promoCode?.endDate) > new Date()
  ) {
    promoCodeDiscount = promoCode.discountAmount;
  }

  // Params that are always included in all requests to finance gateway
  const baseParams = {
    context: {
      // TODO Add to reduxDeal object
      languageCode: getLanguageCode(language),
      countryCode: country,

      distributionBrand: appData.configuration.brandCode,
      siteCode: journeyConfig.financeGateway.siteCode,
      journeyType: journeyConfig.financeGateway.journeyType,
      customer: {
        clientType: journeyConfig.financeGateway.clientType,
      },
    },
    vehicle: {
      lcdv16Code: reduxDeal.carConfiguration.preconfiguration.lcdvCode,

      pricing: {
        basicPriceInclTax: reduxDeal.carConfiguration.netPrice - deduction,
        netPriceInclTax:
          reduxDeal.carConfiguration.netPrice - deduction - promoCodeDiscount,
      },
      features: {
        kind: journeyConfig.financeGateway.featureKind,
        energy: journeyConfig.financeGateway.featureEnergy,
      },
      fiscalProperties: getFiscalProperties(country, reduxDeal, appData),
    },
  };

  return produce(baseParams, draft => {
    // If finance parameters have been updated via finance widget, they should be
    // included in the request body of all requests
    if (widgetParameters) {
      draft.parameters = widgetParameters;
    }

    // All countries except UK have delivery price added to otrCosts
    if (!withAccessories && isDeliverySelected && isDeliveryIncluded && !isGB) {
      draft.vehicle.otrCosts = {
        delivery: {
          amountInclTax: reduxDeal.userProfile.deliveryData.netPrice,
        },
      };
    }

    // Adding accessories in vehicle object depending if package is selected or not
    if (combinedOptions && combinedOptions.length) {
      const options =
        reduxDeal.carConfiguration.combinedOptions ||
        getOptions(
          appData.car.options,
          reduxDeal.carConfiguration.preconfiguration.combinedOptions
        );
      draft.vehicle.options = getTransformedOptions(options);
      draft.vehicle.accessories = getAccessories(reduxDeal, appData, true);
    } else {
      draft.vehicle.accessories = getAccessories(reduxDeal, appData);
    }

    // Only for GB with selected delivery add the delivery cost to vehicle.accessories
    if (isGB && isDeliverySelected) {
      draft.vehicle.accessories = (draft.vehicle.accessories || []).concat(
        prefixItems(
          [
            {
              key: deliveryData.id,
              pricing: {
                amountInclTax: deliveryData.netPrice,
              },
            },
          ],
          FG_DELIVERY_METHODS_PREFIX,
          "key"
        )
      );
    }

    // This flag means the context is prepared for accessories-increase so
    // accessories array must be added to the root object
    if (withAccessories) {
      if (isVACJourneySelected || isLLDJourneySelected) {
        draft.accessories = getTransformedAccessories(appData).concat(
          getDeliveryMethods(appData)
        );
      }
    }
  });
};

const getOptionAccessories = car => {
  return car.options.filter(({ type }) => type === CUSTOMIZE_TYPES.ACCESSORY);
};

const prefixItems = (items, prefix, attribute = "id") => {
  return items.map(
    produce(item => {
      item[attribute] = `${prefix}${item[attribute]}`;
    })
  );
};

const transformItem = item => {
  return {
    key: item.id,
    pricing: {
      amountInclTax: item.netPrice,
    },
  };
};

const getTransformedAccessories = appConfiguration => {
  const optionalAccessories = getOptionAccessories(appConfiguration.car);

  return optionalAccessories.map(transformItem);
};

const getDeliveryMethods = appConfiguration => {
  const prefixedDeliveryMethods = prefixItems(
    appConfiguration.deliveryMethods,
    FG_DELIVERY_METHODS_PREFIX
  );

  return prefixedDeliveryMethods.map(transformItem);
};

function getOptions(carOptions, options) {
  const matchedOptions = [];
  for (const option of options) {
    const match = carOptions.find(carOption => carOption.id === option);
    if (!match) {
      continue;
    }
    matchedOptions.push(match);
  }
  return matchedOptions;
}

const getTransformedOptions = options =>
  options?.map(item => ({
    code: item.id,
    pricing: {
      netPriceInclTax: item.netPrice,
      basicPriceInclTax: item.netPrice,
    },
  }));

module.exports = {
  getFinanceGatewayContext,
};
