const BasePriceAlgorithm = require("./BasePriceAlgorithm");

const {
  getSelectedRegistrationConfiguration,
} = require("../v2/lib/getSelectedRegistrationConfiguration");

const {
  getIsVehicleRegistrationByCustomerSelected,
} = require("../v2/lib/getIsVehicleRegistrationByCustomerSelected");

const { CUSTOMIZE_TYPES } = require("../constants");

class DefaultPriceAlgorithm extends BasePriceAlgorithm {
  constructor({ roundingPrecision }) {
    super({ roundingPrecision });
  }

  getDefaultPrice(deal, appConfig) {
    const { configuration } = appConfig;

    return this.getPrice(
      this.subtract([
        deal.carConfiguration.preconfiguration?.vehicle.netPrice || 0,
        configuration.promoAmount,
      ]),
      configuration.app.solJourney.taxPercentage
    );
  }

  getVehiclePrice(deal, appConfig, isPromoCodeApplicable = false) {
    const { configuration } = appConfig;

    const defaultPrice = this.getDefaultPrice(deal, appConfig);

    const optionPrices = this.getPackPrice(deal, appConfig);

    const servicePrices = deal.carConfiguration.service.map(service =>
      this.getPrice(
        service.netPrice,
        configuration.app.solJourney.taxPercentage
      )
    );

    const accessoryPrices = deal.carConfiguration.accessory.map(accessory =>
      this.getPrice(
        accessory.netPrice,
        configuration.app.solJourney.taxPercentage
      )
    );

    let vehiclePrice = this.addPrices(
      [defaultPrice].concat(optionPrices, servicePrices, accessoryPrices)
    );

    const { promoCode } = deal.financeSimulation || { promoCode: false };

    if (
      !!isPromoCodeApplicable &&
      promoCode?.valid &&
      new Date(promoCode.endDate) > new Date()
    ) {
      vehiclePrice = this.subtractPrices([
        vehiclePrice,
        this.getPrice(
          promoCode.discountAmount,
          configuration.app.solJourney.taxPercentage
        ),
      ]);
    }

    return vehiclePrice;
  }

  getTotalPrice(deal, appConfig, options = {}) {
    const {
      isDeliveryIncluded,
      isRegistrationIncluded,
      isPromoCodeApplicable,
    } = options;
    const { configuration } = appConfig;

    const vehiclePrice = this.getVehiclePrice(
      deal,
      appConfig,
      isPromoCodeApplicable
    );

    let targetPrice = vehiclePrice;

    if (isDeliveryIncluded && deal.userProfile.deliveryData.id) {
      const deliveryPrice = this.getPrice(
        deal.userProfile.deliveryData.netPrice,
        configuration.app.solJourney.taxPercentage
      );

      targetPrice = this.addPrices([targetPrice, deliveryPrice]);
    }

    const registrationSettings = getSelectedRegistrationConfiguration(
      deal.financeSimulation && deal.financeSimulation.vehicleRegistration,
      deal.financeSimulation.journey || deal.financeSimulation.journeyType,
      deal.businessModel,
      configuration
    );

    if (
      registrationSettings &&
      registrationSettings.enabled &&
      registrationSettings.includeInPrice &&
      registrationSettings.discountAmount &&
      isRegistrationIncluded &&
      deal.financeSimulation &&
      getIsVehicleRegistrationByCustomerSelected(
        deal.financeSimulation.vehicleRegistration
      )
    ) {
      targetPrice = this.subtractPrices([
        targetPrice,
        this.getPrice(
          registrationSettings.discountAmount,
          configuration.app.solJourney.taxPercentage
        ),
      ]);
    }

    return targetPrice;
  }

  /**
   * @param  {} deal
   *
   * Use combinedOptions if available
   * use options if not
   */
  getOptions(deal) {
    const combinedOptions = deal.carConfiguration.combinedOptions;
    if (combinedOptions && combinedOptions.length) return combinedOptions;

    return deal.carConfiguration.options;
  }

  /**
   * @param  {} deal
   * @param  {} appConfig
   *
   * Calculate options price
   */
  getPackPrice(deal, appConfig) {
    const options = this.getOptions(deal);

    return this.addPrices(
      options.map(option =>
        this.getPrice(
          option.netPrice,
          appConfig?.configuration?.app?.solJourney?.taxPercentage || 0
        )
      )
    );
  }

  /**
   * @param  {} deal
   * @param  {} appConfig
   *
   * Calculate options price
   */
  getPackPriceBeforeDiscount(deal, appConfig) {
    const options = this.getOptions(deal);

    return this.addPrices(
      options
        .filter(option => option.type !== CUSTOMIZE_TYPES.ACCESSORIES)
        .map(option =>
          this.getPrice(
            option.netPrice,
            appConfig.configuration.app.solJourney.taxPercentage
          )
        )
    );
  }

  getVehiclePriceBeforeDiscount(deal, appConfig) {
    const { configuration } = appConfig;

    const netPrice = deal.carConfiguration.netPrice;
    const packPrice = this.getPackPriceBeforeDiscount(deal, appConfig);

    return this.getPrice(
      this.add([netPrice, packPrice.netPrice]),
      configuration.app.solJourney.taxPercentage
    );
  }
}

module.exports = DefaultPriceAlgorithm;
