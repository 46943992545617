import { priceAlgorithm } from "@lib/priceAlgorithm";

/**
 * Calculates government bonus (eco + scrappage)
 * @param {Object} deal
 * @param {Object} appConfig
 * @returns {{govDiscountPrice: number, productPromoPrice: number}}
 */
export function getCashBonusValues(deal, appConfig) {
  const baseVehiclePrice = priceAlgorithm.getVehiclePriceBeforeDiscount(
    deal,
    appConfig
  );

  const promoCodeAmount = deal.financeSimulation.promoCode?.discountAmount || 0;

  // Vehicle + Pack - PromoCode
  const baseProductPrice = baseVehiclePrice.netPrice - promoCodeAmount;

  const govDiscountPrice =
    priceAlgorithm.getDiscountAmountForPrice?.(
      baseProductPrice,
      deal,
      appConfig
    ) || 0;

  const productPromoPrice = baseProductPrice - govDiscountPrice;

  return { govDiscountPrice, productPromoPrice };
}
