import Router from "next/router";
import { produce } from "immer";

import getConfig from "next/config";
import getDebug from "debug";

import api from "@api";
import gtm from "@gtm/core";
import { changeConfigurationEvent } from "@gtm/events/homepage";
import getPriceAlgorithm from "@shared/PriceAlgorithm/getPriceAlgorithm";
import { i18n } from "@lib/i18n";
import { ROUTES } from "@shared/routing/routes";
import { buildSummarizeDealFromState } from "../helper";
import { setAppDataAction } from "@redux/actions/configActions";
import { extendPreconfigurationWithImage } from "@lib/extendPreconfigurationWithImage";
import { logoutUserAction } from "@redux/actions/authActions";
import { INITIAL_PRICE } from "@redux/initialDealState";
import { journeyTypeToJourneyName } from "@redux/reducers/appConfig";
import { getIsLLDJourneySelected } from "@shared/v2/lib/getIsLLDJourneySelected";
import { getIsLOAJourneySelected } from "@shared/v2/lib/getIsLOAJourneySelected";
import { getIsVACJourneySelected } from "@shared/v2/lib/getIsVACJourneySelected";
import { getIsSCFJourneySelected } from "@shared/v2/lib/getIsSCFJourneySelected";
import { getRequestCanceller } from "@shared/v2/lib/getRequestCanceller";
import { getFinanceGatewayContext } from "@shared/v2/lib/getFinanceGatewayContext";
import { getSantanderContext } from "@shared/v2/lib/getSantanderContext";
import { getIsTargetBusinessModelFinanceEnabled } from "@shared/v2/lib/getIsTargetBusinessModelFinanceEnabled";
import { getIsCashJourneySelected } from "@shared/v2/lib/getIsCashJourneySelected";
import { getPreselectedDelivery } from "@shared/v2/lib/getPreselectedDelivery";
import { getIsB2BSelected } from "@shared/v2/lib/getIsB2BSelected";
import { isIndexOrConfigPage } from "@shared/helper/isIndexOrConfigPage";
import { isConfigurationAvailable } from "@shared/helper/isConfigurationAvailable";

import { getIsDeliveryMethodAvailable } from "@lib/getIsDeliveryMethodAvailable";
import { toast } from "@lib/reactToastify";
import { getPreconfigurationMandatoryOptions } from "@lib/getPreconfigurationMandatoryOptions";
import { getConfigurationPathnameFromRedux } from "@lib/getConfigurationPathnameFromRedux";
import { getIsDeliveryPriceIncluded } from "@lib/getIsDeliveryPriceIncluded";
import { getIsRegistrationDiscountApplied } from "@shared/v2/lib/getIsRegistrationDiscountApplied";
import { setLastChangedAtAction } from "@redux/actions/deprecatedActions";
import { getIsLeasingJourneySelected } from "@shared/v2/lib/getIsLeasingJourneySelected";
import { fromOpencartDealToLocalDeal } from "@hooks/useFormattedDeal";
import { getIsProductionMode } from "@lib/getIsProductionMode";

import {
  setBasketErrorCodesAction,
  resetFinanceWidgetErrorCodesAction,
} from "@redux/actions/errorsActions";

import {
  setUpdateStepsRequestSuccessAction,
  setUpdateStepsRequestPendingAction,
  setUpdateStepsRequestFailureAction,
  setIsLOAPriceBreakRequestPendingAction,
  setIsLOAPriceBreakRequestErrorAction,
  setIsLOAPriceBreakRequestSuccessAction,
  setIsLLDPriceBreakRequestPendingAction,
  setIsLLDPriceBreakRequestErrorAction,
  setIsLLDPriceBreakRequestSuccessAction,
  setIsVACPriceBreakRequestPendingAction,
  setIsVACPriceBreakRequestErrorAction,
  setIsVACPriceBreakRequestSuccessAction,
  setIsSCFSimulationRequestPendingAction,
  setIsSCFSimulationRequestErrorAction,
  setIsSCFSimulationRequestSuccessAction,
} from "@redux/actions/requestsActions";

import {
  BUSINESS_MODELS,
  REQUEST_ERROR_CODES,
  UPDATE_STEPS_ERROR_CODES,
  JOURNEY_TYPE,
  DEAL_STATES,
  CTA_SOL_TYPES,
  DEAL_TYPE,
  IS_CLIENT,
  PROMO_CODE_JOURNEY_TYPES,
  SESSION_STORAGE_KEYS,
} from "@shared/constants";
import { sessionStorageClient } from "utils/sessionStorageClient";
import { getAvailableJourneys } from "@shared/helper/getAvailableJourneys";

const debug = getDebug("app:redux:deal:actions");

export const DEAL_ACTION_TYPES = {
  PATCH_USER_PROFILE: "PATCH_USER_PROFILE",
  SET_DELIVERY_DATA: "SET_DELIVERY_DATA",
  SET_ACCESSORY_INCREASE: "SET_ACCESSORY_INCREASE",
  SET_FINANCE_DELIVERY_PRICES: "SET_FINANCE_DELIVERY_PRICES",
  SET_BUSINESS_MODEL: "SET_BUSINESS_MODEL",
  UPDATE_DELIVERY_CONTACT_DATA: "UPDATE_DELIVERY_CONTACT_DATA",
  SET_DELIVERY_CONTACT_DATA: "SET_DELIVERY_CONTACT_DATA",
  SET_VEHICLE_REGISTRATION: "SET_VEHICLE_REGISTRATION",
  SET_DELIVERY_DEALER: "SET_DELIVERY_DEALER",
  SET_JOURNEY: "SET_JOURNEY",
  UPDATE_CAR_CONFIGURATION: "UPDATE_CAR_CONFIGURATION",
  PATCH_CAR_CONFIGURATION: "PATCH_CAR_CONFIGURATION",
  SET_DEAL_SAVED_BY_USER: "SET_DEAL_SAVED_BY_USER",
  SET_DEAL_EDITED_BY_USER: "SET_DEAL_EDITED_BY_USER",
  SET_SAVED_DEALS: "SET_SAVED_DEALS",
  SET_FINANCE_WIDGET_PARAMETERS: "SET_FINANCE_WIDGET_PARAMETERS",
  UPDATE_PRECONFIGURATION_LEAD_TIMES: "UPDATE_PRECONFIGURATION_LEAD_TIMES",
  SET_SALESMAN: "SET_SALESMAN",
  SET_GIT_ID: "SET_GIT_ID",
  UPDATE_GIT_ID: "UPDATE_GIT_ID",
  SET_IS_SELECTION_CONFIRMED: "SET_IS_SELECTION_CONFIRMED",
  SET_IS_FINANCE_CHANGE_ALLOWED_IN_OFFER_STATE:
    "SET_IS_FINANCE_CHANGE_ALLOWED_IN_OFFER_STATE",
  SET_CAR_PRICE: "SET_CAR_PRICE",
  POST_DEAL_PENDING: "POST_DEAL_PENDING_ACTION",
  POST_DEAL_SUCCESS: "POST_DEAL_SUCCESS_ACTION",
  POST_DEAL_FAILURE: "POST_DEAL_FAILURE_ACTION",
  SET_CURRENT_CTA: "SET_CURRENT_CTA",
  SET_FINANCE_SERVICES: "SET_FINANCE_SERVICES",
  SET_CURRENT_DEAL: "SET_CURRENT_DEAL",
  LOGOUT_USER: "LOGOUT_USER",
  SET_DEAL: "SET_DEAL",
  SET_PREVIOUS_REDIRECT: "SET_PREVIOUS_REDIRECT",
  RESET_DEAL: "RESET_DEAL",
  SET_FINANCING_DEFAULT_PRICE: "SET_FINANCING_DEFAULT_PRICE",
  SET_CASH_DEFAULT_PRICE: "SET_CASH_DEFAULT_PRICE",
  SET_FINANCING_TOTAL_PRICE: "SET_FINANCING_TOTAL_PRICE",
  SET_CASH_TOTAL_PRICE: "SET_CASH_TOTAL_PRICE",
  UPDATE_DELIVERY_PRICE: "UPDATE_DELIVERY_PRICE",
  UPDATE_FINANCE_WIDGET_PARAMETERS: "UPDATE_FINANCE_WIDGET_PARAMETERS",
  SET_CAR_PRECONFIGURATION_FROM_URL: "SET_CAR_PRECONFIGURATION_FROM_URL",
  SET_CASH_PRICES: "SET_CASH_PRICES",
  SET_IS_REFRESHING_DEAL: "SET_IS_REFRESHING_DEAL",
  UPDATE_CAR_PRECONFIGURATION: "UPDATE_CAR_PRECONFIGURATION",
  SET_SCRAPPAGE: "SET_SCRAPPAGE",
  TOGGLE_SCRAPPAGE: "TOGGLE_SCRAPPAGE",
  SET_LOA_PRICE_BREAKDOWN: "SET_LOA_PRICE_BREAKDOWN",
  SET_LLD_PRICE_BREAKDOWN: "SET_LLD_PRICE_BREAKDOWN",
  SET_VAC_PRICE_BREAKDOWN: "SET_VAC_PRICE_BREAKDOWN",
  SET_SCF_PRICE_BREAKDOWN: "SET_SCF_PRICE_BREAKDOWN",
  SET_VEHICLES_QUANTITY: "SET_VEHICLES_QUANTITY",
  SET_BANK_PROVIDER: "SET_BANK_PROVIDER",
  SET_PROMO_CODE: "SET_PROMO_CODE",
  SET_PROMO_CODE_INVALID: "SET_PROMO_CODE_INVALID",
  REMOVE_PROMO_CODE: "REMOVE_PROMO_CODE",
};

const { publicRuntimeConfig } = getConfig();

const { COUNTRY } = publicRuntimeConfig;

const priceAlgorithm = getPriceAlgorithm(COUNTRY);

export const setLastCtaAction = ctaAction => ({
  type: DEAL_ACTION_TYPES.SET_CURRENT_CTA,
  data: ctaAction,
});

export const updateTestDriveLastCtaAction = ctaAction => {
  return async (dispatch, getState) => {
    const state = getState();

    const { testDrive } = state;
    const { address, point, slot } = testDrive;

    const testDriveSession = {
      selectedSlot: slot,
      address,
      point,
    };

    await api.updateSession({
      lastCta: ctaAction,
      testDrive: testDriveSession,
    });

    dispatch(setLastCtaAction(ctaAction));
  };
};

export const updateLastCtaAction = ctaAction => {
  return async dispatch => {
    await api.updateLastCtaToSession(ctaAction);

    dispatch(setLastCtaAction(ctaAction));
  };
};

export const postDealAction = (
  ctaAction,
  dealType,
  dealState = null,
  resetFinanceWidgetErrors = true,
  updateSource = "generic"
) => {
  return async (dispatch, getState) => {
    const state = getState();

    const { deal, auth, appConfig } = state;
    const { isTrusted, isGuest } = auth;
    const guestCheckoutEnabled =
      appConfig.configuration.app.guestCheckout?.enabled ?? false;

    const isProduction = getIsProductionMode();

    const isKnown = isTrusted || (guestCheckoutEnabled && isGuest);
    if (!isKnown) {
      // Ignore CTA_SOL_TYPES.CONFIGURATION_SAVED_BY_USER, this one is only intended for use on the backend
      // and would interfere with behaviour of the basket breadcrumbs, for example
      if (
        ctaAction &&
        ctaAction !== CTA_SOL_TYPES.CONFIGURATION_SAVED_BY_USER
      ) {
        dealType === DEAL_TYPE.TEST_DRIVE
          ? await dispatch(updateTestDriveLastCtaAction(ctaAction))
          : await dispatch(updateLastCtaAction(ctaAction));
      }

      // If user is not considered trusted or isn't guest, we don't want to call any update steps

      return;
    }

    const { currentDeal, carConfiguration } = deal;

    const token = currentDeal?.token ?? null;

    // This is just a sanity check
    if (!currentDeal || !token) {
      if (!isProduction) {
        console.log(
          "WARNING: User is being redirected to the login page, because post deal action found no token!"
        );
      }
      await dispatch(logoutUserAction());

      await Router.push(ROUTES.MY_ACCOUNT_LOGIN);

      return;
    }

    if (resetFinanceWidgetErrors) {
      dispatch(resetFinanceWidgetErrorCodesAction());
    }

    dispatch(setUpdateStepsRequestPendingAction());

    try {
      const latestPreconfiguration = appConfig.car.preconfigurations.find(
        ({ id }) => id === carConfiguration.preconfiguration.id
      );

      if (latestPreconfiguration) {
        dispatch(
          updatePreconfigurationLeadTimes(latestPreconfiguration.leadTimes)
        );
      }

      const latestState = getState();

      const lead = buildSummarizeDealFromState(
        latestState,
        dealType,
        dealState,
        ctaAction,
        updateSource
      );

      const financeGateway = {
        financeWidgetParameters: deal.financeSimulation.financeWidgetParameters,
      };
      const { data } = await api.updateSteps(
        token,
        lead,
        financeGateway,
        latestState.deal
      );

      // data.deal contains the updated deal
      // data.validationResult.suppressedErrorCodes contains an array of suppressed error codes
      // data.appData contains the fresh application configuration, but only if any suppressedErrorCodes are present

      dispatch(setCurrentDealAction(data.deal));

      dispatch(
        setBasketErrorCodesAction(data.validationResult.suppressedErrorCodes)
      );

      dispatch(setUpdateStepsRequestSuccessAction());

      const { validationResult } = data;
      const { suppressedErrorCodes } = validationResult;

      // If there were any suppressed error codes (meaning codes which should not prevent us from continuing with the journey)
      // then we apply the fresh appData and update parts of our deal which require attention
      if (suppressedErrorCodes.length > 0) {
        dispatch(setAppDataAction(data.appData));

        if (
          suppressedErrorCodes.includes(
            REQUEST_ERROR_CODES.INVALID_LEAD_TIME_ERROR
          )
        ) {
          const latestPreconfiguration = data.appData.car.preconfigurations.find(
            ({ id }) => id === carConfiguration.preconfiguration.id
          );

          dispatch(
            updatePreconfigurationLeadTimes(latestPreconfiguration.leadTimes)
          );
        }
      }

      // Update lastCtaAction only if update steps was successful (don't break the ProgressBar for example)
      // Ignore CTA_SOL_TYPES.CONFIGURATION_SAVED_BY_USER, this one is only intended for use on the backend
      // and would interfere with behaviour of the basket breadcrumbs, for example
      if (
        ctaAction &&
        ctaAction !== CTA_SOL_TYPES.CONFIGURATION_SAVED_BY_USER
      ) {
        dealType === DEAL_TYPE.TEST_DRIVE
          ? await dispatch(updateTestDriveLastCtaAction(ctaAction))
          : await dispatch(updateLastCtaAction(ctaAction));
      }
    } catch (error) {
      if (
        error.response?.data?.errorCode ===
        REQUEST_ERROR_CODES.LEAD_IS_ALREADY_ORDER
      ) {
        dispatch(setCurrentDealAction(error.response.data.lead));
      }

      dispatch(handlePostDealActionErrorAction(error));

      throw error;
    }
  };
};

export const handlePostDealActionErrorAction = error => async (
  dispatch,
  getState
) => {
  const state = getState();
  const { deal } = state;

  if (!error.isAxiosError) {
    debug(error);

    return;
  }

  if (error.code === UPDATE_STEPS_ERROR_CODES.ECONNABORTED) {
    return toast.error(i18n.t("notify.apiDefaultError"), {}, error);
  }

  const { status, data } = error.response;

  const homepagePathnameWithConfiguration = getConfigurationPathnameFromRedux(
    state,
    i18n.language
  );

  if (status === 401) {
    await dispatch(logoutUserAction());

    await Router.push(homepagePathnameWithConfiguration);

    return;
  }

  if (status === 422) {
    if (
      [
        REQUEST_ERROR_CODES.MOP_UPDATE_FAILED,
        REQUEST_ERROR_CODES.SELECTED_PARKOPOLY_TIME_IS_NOT_AVAILABLE,
      ].includes(data.errorCode)
    ) {
      return;
    }

    if (
      data.errorCode ===
      REQUEST_ERROR_CODES.FAILED_TO_REDIRECT_TO_ORDER_COMPLETE
    ) {
      await Router.push(ROUTES.ORDER_CONFIRMATION, {
        journey: deal.financeSimulation.type,
      });

      return;
    }

    const isLLDJourneySelected = getIsLLDJourneySelected(
      deal.financeSimulation.journey
    );

    if (
      [
        REQUEST_ERROR_CODES.INVALID_CTA_ACTION,
        REQUEST_ERROR_CODES.CASH_PRICE_MISMATCH_ERROR,
      ].includes(data.errorCode)
    ) {
      await dispatch(logoutUserAction());

      await Router.push(homepagePathnameWithConfiguration);

      return;
    }

    if (data.errorCode === REQUEST_ERROR_CODES.DELIVERY_POINT_UNAVAILABLE) {
      dispatch(
        setBasketErrorCodesAction([
          REQUEST_ERROR_CODES.DELIVERY_POINT_UNAVAILABLE,
        ])
      );

      return;
    }

    if (
      data.errorCodes?.includes(
        REQUEST_ERROR_CODES.INVALID_PRECONFIGURATION_ERROR
      )
    ) {
      dispatch(
        setBasketErrorCodesAction([
          REQUEST_ERROR_CODES.PRECONFIGURATION_UNAVAILABLE,
        ])
      );

      return;
    }

    dispatch(setAppDataAction(data.appData));
    dispatch(setUpdateStepsRequestFailureAction(error));

    if (
      data.errorCodes.includes(
        REQUEST_ERROR_CODES.INVALID_FINANCE_PRICE_ERROR
      ) &&
      isLLDJourneySelected
    ) {
      dispatch(calculatePricesAction());
    }
  }
};

export const patchUserProfileAction = user => ({
  type: DEAL_ACTION_TYPES.PATCH_USER_PROFILE,
  data: user,
});

export const setDeliveryDataAction = deliveryData => dispatch => {
  dispatch({
    type: DEAL_ACTION_TYPES.SET_DELIVERY_DATA,
    data: deliveryData,
  });
};

export const setDeliveryContactAction = deliveryContact => ({
  type: DEAL_ACTION_TYPES.SET_DELIVERY_CONTACT_DATA,
  data: deliveryContact,
});

export const updateDeliveryContactDataAction = contactData => async (
  dispatch,
  getState
) => {
  const { deal } = getState();

  const initialDeliveryContact = deal.userProfile.deliveryContact;

  dispatch({
    type: DEAL_ACTION_TYPES.UPDATE_DELIVERY_CONTACT_DATA,
    data: contactData,
  });

  try {
    await api.updateSession({ delivery: { contact: contactData } });
  } catch (error) {
    debug(`❌ Couldn't update session with delivery data\n\n`, error);

    dispatch({
      type: DEAL_ACTION_TYPES.UPDATE_DELIVERY_CONTACT_DATA,
      data: initialDeliveryContact,
    });
  }
};

export const setVehicleRegistrationDataAction = vehicleRegistration => ({
  type: DEAL_ACTION_TYPES.SET_VEHICLE_REGISTRATION,
  data: vehicleRegistration,
});

export const setDeliveryDealerAction = dealer => ({
  type: DEAL_ACTION_TYPES.SET_DELIVERY_DEALER,
  data: dealer,
});

export const setJourneyAction = fundingType => ({
  type: DEAL_ACTION_TYPES.SET_JOURNEY,
  data: fundingType,
});

const journeySessionUpdateRequestCanceller = getRequestCanceller();

export const updateJourneyAction = nextJourney => {
  return async (dispatch, getState) => {
    const {
      appConfig,
      deal: initialDeal,
      deal: { businessModel },
      auth,
    } = getState();
    const initialJourney = initialDeal.financeSimulation.journey;

    const { promoCode } = initialDeal.financeSimulation;

    const journey =
      nextJourney === JOURNEY_TYPE.CASH ? "cashJourney" : "financeJourney";

    if (
      (promoCode?.journeyType !== PROMO_CODE_JOURNEY_TYPES.ALL &&
        promoCode?.journeyType !== nextJourney) ||
      !appConfig.configuration.app.promoCodes?.[businessModel]?.[journey]
        ?.enabled
    ) {
      if (!auth.isTrusted) {
        api.removePromoCodeFromSession();
      }

      dispatch(removePromoCodeAction());

      sessionStorageClient.removeItem(SESSION_STORAGE_KEYS.PROMOCODE);
    }

    dispatch(setJourneyAction(nextJourney));
    await dispatch(calculatePricesAction());

    journeySessionUpdateRequestCanceller(cancelSource =>
      api.updateJourneySession(nextJourney, { cancelToken: cancelSource.token })
    ).catch(error => {
      debug(`❌ Couldn't update journey\n\n`, error);

      dispatch(setJourneyAction(initialJourney));

      toast.error(
        i18n.t("notify.failedToUpdateJourneyInSession"),
        {
          toastId: "failed-to-update-journey-in-session",
        },
        error
      );
    });
  };
};

export const updateAvailableJourneyAction = preconfiguration => {
  return async (dispatch, getState) => {
    const {
      appConfig: {
        car: { preconfigurations },
        configuration,
      },
      auth: { isTrusted },
      deal: { businessModel, financeSimulation },
    } = getState();
    const isB2BSelected = getIsB2BSelected(businessModel);
    const { status } = preconfiguration;

    if (!isConfigurationAvailable(isB2BSelected)(preconfiguration)) {
      const defaultPreconfiguration = preconfigurations.find(preconfig => {
        const { default: b2cDefault, b2bDefault } = preconfig;
        return (
          isConfigurationAvailable(isB2BSelected)(preconfig) &&
          (isB2BSelected ? b2bDefault : b2cDefault)
        );
      });

      if (!isTrusted) {
        api.removePromoCodeFromSession();
      }

      dispatch(removePromoCodeAction());
      dispatch(updateCarPreconfigurationAction(defaultPreconfiguration));
      dispatch(setLastChangedAtAction(Date.now()));

      sessionStorageClient.removeItem(SESSION_STORAGE_KEYS.PROMOCODE);

      gtm.fire(changeConfigurationEvent(preconfiguration.label));
    } else if (Array.isArray(status) && status.length === 4) {
      const isCashJourneySelected = getIsCashJourneySelected(
        financeSimulation.journey
      );
      const isLeasingJourneySelected = getIsLeasingJourneySelected(
        financeSimulation.journey
      );
      const [
        b2cCashActive,
        b2cFinanceActive,
        b2bCashActive,
        b2bFinanceActive,
      ] = status;

      let journey = financeSimulation.journey;
      if (
        isCashJourneySelected &&
        (isB2BSelected ? !b2bCashActive : !b2cCashActive)
      ) {
        journey =
          getAvailableJourneys(configuration).slice(1)?.[0] || JOURNEY_TYPE.LLD;
      }

      if (
        isLeasingJourneySelected &&
        (isB2BSelected ? !b2bFinanceActive : !b2cFinanceActive)
      ) {
        journey = JOURNEY_TYPE.CASH;
      }

      if (journey !== financeSimulation.journey) {
        dispatch(updateJourneyAction(journey));
      }
    }
  };
};

export const patchCarConfigurationAction = data => ({
  type: DEAL_ACTION_TYPES.PATCH_CAR_CONFIGURATION,
  data,
});

export const updateCarConfigurationAction = data => async (
  dispatch,
  getState
) => {
  const { deal: initialDeal } = getState();

  const nextConfiguration = {
    ...initialDeal.carConfiguration,
    ...data,
  };

  // To keep selected accessories that are available for the new configuration
  nextConfiguration.accessory =
    nextConfiguration.accessory?.filter(accessory =>
      nextConfiguration.preconfiguration.options.includes(accessory.id)
    ) || [];
  // To reset all accessories on config change
  // nextConfiguration.accessory = [];

  dispatch({
    type: DEAL_ACTION_TYPES.UPDATE_CAR_CONFIGURATION,
    data: nextConfiguration,
  });

  dispatch(calculatePricesAction());

  if (IS_CLIENT) {
    api
      .updateSession({
        configuration: {
          preconfiguration: nextConfiguration.preconfiguration.id,
          service: nextConfiguration.service[0]?.id ?? null,
          accessories: nextConfiguration.accessory.map(
            accessory => accessory.id
          ),
        },
      })
      .catch(error => {
        debug(`❌ Session update failed\n\n`, error);

        dispatch({
          type: DEAL_ACTION_TYPES.UPDATE_CAR_CONFIGURATION,
          data: initialDeal.carConfiguration,
        });

        dispatch(calculatePricesAction());
      });
  }
};

export const updateCarPreconfigurationAction = (
  preconfiguration,
  fetchFinanceData = true
) => async (dispatch, getState) => {
  const { appConfig } = getState();

  dispatch({
    type: DEAL_ACTION_TYPES.UPDATE_CAR_PRECONFIGURATION,
    data: extendPreconfigurationWithImage(preconfiguration),
  });

  const targetOptions = getPreconfigurationMandatoryOptions(
    preconfiguration.options,
    appConfig.car.options
  );

  const targetCombinedOptions = getPreconfigurationMandatoryOptions(
    preconfiguration.combinedOptions,
    appConfig.car.options
  );

  // Reset the finance widget parameters as some models offer larger amount
  // of deposit than others and that breaks requests to finance gateway
  // ! do that only if finance data will be refetched, otherwise causes this:
  // https://drivvn.atlassian.net/browse/APP-24726
  fetchFinanceData && dispatch(setFinanceWidgetParametersAction(null));

  await dispatch(
    updateCarConfigurationAction(
      { options: targetOptions, combinedOptions: targetCombinedOptions },
      fetchFinanceData
    )
  );
};

export const setIsDealSavedByUserAction = isSavedByUser => ({
  type: DEAL_ACTION_TYPES.SET_DEAL_SAVED_BY_USER,
  data: isSavedByUser,
});

export const setFinanceWidgetParametersAction = financeWidgetParameters => dispatch => {
  dispatch(resetFinanceWidgetErrorCodesAction());

  dispatch({
    type: DEAL_ACTION_TYPES.SET_FINANCE_WIDGET_PARAMETERS,
    data: financeWidgetParameters,
  });
};

export const updatePreconfigurationLeadTimes = leadTimes => ({
  type: DEAL_ACTION_TYPES.UPDATE_PRECONFIGURATION_LEAD_TIMES,
  data: leadTimes,
});

export const setSalesmanAction = salesman => ({
  type: DEAL_ACTION_TYPES.SET_SALESMAN,
  data: salesman,
});

export const setGitIdAction = gitId => ({
  type: DEAL_ACTION_TYPES.SET_GIT_ID,
  data: gitId,
});

export const updateGitIdAction = gitId => async (dispatch, getState) => {
  const { deal } = getState();

  const initialGitId = deal.gitId;

  dispatch({
    type: DEAL_ACTION_TYPES.UPDATE_GIT_ID,
    data: gitId,
  });

  try {
    await api.updateSession({ gitId });
  } catch (error) {
    dispatch({
      type: DEAL_ACTION_TYPES.UPDATE_GIT_ID,
      data: initialGitId,
    });
  }
};

export const setIsSelectionConfirmedAction = isSelectionConfirmed => ({
  type: DEAL_ACTION_TYPES.SET_IS_SELECTION_CONFIRMED,
  data: isSelectionConfirmed,
});

export const updateIsSelectionConfirmedAction = isSelectionConfirmed => {
  return async dispatch => {
    try {
      await api.updateIsSelectionConfirmedSession(isSelectionConfirmed);
      dispatch(setIsSelectionConfirmedAction(isSelectionConfirmed));
    } catch (error) {
      console.warn("updateIsSelectionConfirmedAction failed");
    }
  };
};

export const setIsDealPriceLockedAction = isPriceLocked => ({
  type: DEAL_ACTION_TYPES.SET_IS_FINANCE_CHANGE_ALLOWED_IN_OFFER_STATE,
  data: isPriceLocked,
});

export const setCurrentDealAction = data => ({
  type: DEAL_ACTION_TYPES.SET_CURRENT_DEAL,
  data,
});

export const setDealAction = deal => ({
  type: DEAL_ACTION_TYPES.SET_DEAL,
  data: deal,
});

export const setDealFromCurrentDealAction = () => (dispatch, getState) => {
  const state = getState();

  const nextDeal = fromOpencartDealToLocalDeal(
    state.deal.currentDeal,
    state.deal,
    state.appConfig
  );

  dispatch(setDealAction(nextDeal));
};

const setCashPricesAction = prices => ({
  type: DEAL_ACTION_TYPES.SET_CASH_PRICES,
  data: prices,
});

const setConfigurationPriceAction = prices => ({
  type: DEAL_ACTION_TYPES.SET_CAR_PRICE,
  data: prices,
});

export const resetDealAction = deleteCurrentDealToken => dispatch => {
  dispatch({
    type: DEAL_ACTION_TYPES.RESET_DEAL,
    data: deleteCurrentDealToken,
  });

  dispatch(updateDeliveryPriceAction(INITIAL_PRICE));
};

export const setIsRefreshingDeal = isRefreshingDeal => ({
  type: DEAL_ACTION_TYPES.SET_IS_REFRESHING_DEAL,
  data: isRefreshingDeal,
});

export const createNewDealFromExistingAction = () => async (
  dispatch,
  getState
) => {
  const { deal, auth, appConfig } = getState();

  const currentDealToken = deal.currentDeal?.token;
  const currentBusinessModel = deal.businessModel;

  const isGuest =
    (appConfig.configuration.app.guestCheckout?.enabled ?? false) &&
    auth?.isGuest;
  if ((!auth?.isTrusted && !isGuest) || !currentDealToken) {
    throw new Error(
      "User must be trusted or Guest in order to call createNewDealFromExisting action!"
    );
  }

  dispatch(setIsRefreshingDeal(true));

  const { data: newDealFromExisting } = await api.createNewDealFromExisting(
    currentDealToken,
    currentBusinessModel
  );

  dispatch(resetDealAction(true));

  dispatch(setCurrentDealAction(newDealFromExisting));
  dispatch(setIsRefreshingDeal(false));

  dispatch(calculatePricesAction());
};

export const calculatePricesAction = (
  pathname = IS_CLIENT ? Router.pathname : null
) => async (dispatch, getState) => {
  const isProduction = getIsProductionMode();

  if (!isProduction && !pathname) {
    throw new Error(`Pathname must contain a value instead got '${pathname}'`);
  }

  const { appConfig, deal } = getState();

  const isDeliveryPreselected = !!getPreselectedDelivery(
    appConfig,
    deal.financeSimulation.journey,
    deal.businessModel
  );

  const defaultCashPrice = priceAlgorithm.getDefaultPrice(deal, appConfig);
  const vehicleCashPrice = priceAlgorithm.getVehiclePrice(deal, appConfig);

  const isDeliveryIncluded = getIsDeliveryPriceIncluded(
    pathname,
    deal.lastCta,
    isDeliveryPreselected
  );

  const isRegistrationIncluded = getIsRegistrationDiscountApplied(
    pathname,
    deal.lastCta
  );

  const totalCashPrice = priceAlgorithm.getTotalPrice(deal, appConfig, {
    isDeliveryIncluded,
    isRegistrationIncluded,
    isPromoCodeApplicable: !isIndexOrConfigPage(pathname || Router.pathname),
  });

  dispatch(
    setCashPricesAction({
      defaultCashPrice,
      vehicleCashPrice,
      totalCashPrice,
    })
  );

  const configurationPriceBase = priceAlgorithm.getPrice(
    deal.carConfiguration.preconfiguration.vehicle.netPrice,
    appConfig.configuration.app.solJourney.taxPercentage
  );

  dispatch(
    setConfigurationPriceAction(
      produce(configurationPriceBase, draft => {
        draft.promo = appConfig.configuration.promoAmount;

        const { netPrice: priceAfterPromo } = priceAlgorithm.getDefaultPrice(
          deal,
          appConfig
        );

        draft.priceAfterPromo = priceAfterPromo;
      })
    )
  );

  const selectedJourney = deal.financeSimulation.journey;

  if (
    IS_CLIENT &&
    getIsTargetBusinessModelFinanceEnabled(
      deal.businessModel,
      appConfig.configuration
    ) &&
    getIsLeasingJourneySelected(selectedJourney)
  ) {
    const withoutPromoCode = isIndexOrConfigPage(pathname);
    if (selectedJourney === JOURNEY_TYPE.SCF) {
      dispatch(
        fetchSantanderSimulationAction({
          withoutPromoCode,
        })
      );
    } else {
      if ([ROUTES.INDEX, ROUTES.CONFIG, ROUTES.BASKET].includes(pathname)) {
        dispatch(
          fetchAccessoriesIncreaseAction({
            withoutPromoCode,
          })
        );
      }

      dispatch(
        fetchFinanceSimulationAction({
          withoutPromoCode,
        })
      );
    }
  }
};

export const updateDeliveryPriceAction = (
  deliveryPrice,
  pathname = IS_CLIENT ? Router.pathname : null
) => dispatch => {
  dispatch({
    type: DEAL_ACTION_TYPES.UPDATE_DELIVERY_PRICE,
    data: deliveryPrice,
  });

  dispatch(calculatePricesAction(pathname));
};

export const setCarPreconfigurationFromUrlAction = data => (
  dispatch,
  getState
) => {
  const { appConfig } = getState();

  const extendedData = produce(data, draft => {
    draft.preconfiguration = extendPreconfigurationWithImage(
      data.preconfiguration
    );

    draft.options = getPreconfigurationMandatoryOptions(
      data.preconfiguration.options,
      appConfig.car.options
    );
    draft.combinedOptions = getPreconfigurationMandatoryOptions(
      data.preconfiguration.combinedOptions,
      appConfig.car.options
    );
  });

  dispatch({
    type: DEAL_ACTION_TYPES.SET_CAR_PRECONFIGURATION_FROM_URL,
    data: extendedData,
  });
};

export const forceUpdateCarConfigurationDataAction = () => (
  dispatch,
  getState
) => {
  const { appConfig, deal } = getState();
  const { carConfiguration } = deal;

  const selectedPreconfigurationId = carConfiguration.preconfiguration.id;
  const { car } = appConfig;

  const getUpdatedOptions = targetOptions =>
    targetOptions
      .map(targetOption =>
        car.options.find(option => option.id === targetOption.id)
      )
      .filter(Boolean);

  const getUpdatedServices = targetServices =>
    targetServices
      .map(targetService =>
        car.services.find(service => service.id === targetService.id)
      )
      .filter(Boolean);

  const updatedCarConfiguration = produce(carConfiguration, draft => {
    const preconfiguration = car.preconfigurations.find(
      ({ id }) => id === selectedPreconfigurationId
    );

    draft.preconfiguration = {
      ...preconfiguration,
      image: carConfiguration.preconfiguration.image,
    };

    const { netPrice, basePrice, tax } = priceAlgorithm.getDefaultPrice(
      deal,
      appConfig
    );

    draft.netPrice = netPrice;
    draft.basePrice = basePrice;
    draft.tax = tax;

    draft.promo = appConfig.configuration.promoAmount;
    draft.priceAfterPromo = netPrice;

    draft.accessory = getUpdatedOptions(draft.accessory);
    draft.service = getUpdatedServices(draft.service);
    draft.options = getUpdatedOptions(draft.options);
    draft.combinedOptions = getUpdatedOptions(draft.combinedOptions);
  });

  dispatch(updateCarConfigurationAction(updatedCarConfiguration));
};

export const resetDeliveryData = () => async (dispatch, getState) => {
  const state = getState();

  const { deal, appConfig } = getState();

  const preselectedDelivery = getPreselectedDelivery(
    state.appConfig,
    deal.financeSimulation.journey,
    deal.businessModel
  );

  const preselectedDeliveryMethod = appConfig.deliveryMethods
    .filter(d => !!d.enabled)
    .find(d => d.id === preselectedDelivery);

  const initialDeliveryData = preselectedDeliveryMethod || { type: null };

  dispatch(setDeliveryDataAction(initialDeliveryData));

  if (
    !deal.currentDeal?.token ||
    deal.currentDeal?.dealState !== DEAL_STATES.DEAL
  ) {
    return;
  }

  const updatedCurrentDeal = produce(deal.currentDeal, draft => {
    if (!draft.extendedCustomerInfo?.deliveryData) {
      return;
    }

    draft.extendedCustomerInfo.deliveryData = initialDeliveryData;
    draft.deliveryMethod = initialDeliveryData;
  });

  dispatch(setCurrentDealAction(updatedCurrentDeal));

  return dispatch(
    postDealAction(CTA_SOL_TYPES.VEHICLE_SELECTED, DEAL_TYPE.DEAL, null)
  );
};

export const setBusinessModelAction = (
  targetBusinessModel,
  pathname = IS_CLIENT ? Router.pathname : null
) => async (dispatch, getState) => {
  const state = getState();
  const { deal, appConfig, auth } = state;
  const { userProfile, financeSimulation } = deal;
  const { journey, promoCode } = financeSimulation;

  const isDeliveryMethodAvailable = getIsDeliveryMethodAvailable(
    targetBusinessModel,
    userProfile.deliveryData
  );

  if (!isDeliveryMethodAvailable) {
    await dispatch(resetDeliveryData());
  }

  if (
    promoCode &&
    !appConfig.configuration.app.promoCodes?.[targetBusinessModel]?.[
      `${journey}Journey`
    ]?.enabled
  ) {
    if (!auth.isTrusted) {
      api.removePromoCodeFromSession();
    }

    dispatch(removePromoCodeAction());

    sessionStorageClient.removeItem(SESSION_STORAGE_KEYS.PROMOCODE);
  }

  dispatch({
    type: DEAL_ACTION_TYPES.SET_BUSINESS_MODEL,
    data: targetBusinessModel,
  });

  if (getIsLLDJourneySelected(journey) || getIsLOAJourneySelected(journey)) {
    dispatch(setFinanceWidgetParametersAction(null));

    if (state.deal.carConfiguration.preconfiguration.vehicle?.netPrice) {
      dispatch(calculatePricesAction(pathname));
    }
  }
};

export const updateBusinessModelAction = targetBusinessModel => async (
  dispatch,
  getState
) => {
  const state = getState();
  const {
    businessModel: currentBusinessModel,
    financeSimulation,
    userProfile,
  } = state.deal;

  const isProduction = getIsProductionMode();

  try {
    dispatch({
      type: DEAL_ACTION_TYPES.SET_BUSINESS_MODEL,
      data: targetBusinessModel,
    });

    const isTargetBusinessModelFinanceEnabled = getIsTargetBusinessModelFinanceEnabled(
      targetBusinessModel,
      state.appConfig.configuration
    );

    const isCashJourneySelected = getIsCashJourneySelected(
      financeSimulation.journey
    );

    const targetJourney =
      !isCashJourneySelected && isTargetBusinessModelFinanceEnabled
        ? financeSimulation.journey
        : JOURNEY_TYPE.CASH;

    const isDeliveryMethodAvailable = getIsDeliveryMethodAvailable(
      targetBusinessModel,
      userProfile.deliveryData
    );

    if (!isDeliveryMethodAvailable) {
      await dispatch(resetDeliveryData());
    }

    return api.updateSession({
      businessModel: targetBusinessModel,
      journey: targetJourney,
    });
  } catch (error) {
    if (!isProduction) {
      console.warn(`Couldn't update session!\n\n`, error);
    }

    dispatch({
      type: DEAL_ACTION_TYPES.SET_BUSINESS_MODEL,
      data: currentBusinessModel,
    });
  }
};

export const finalizeOrderAction = (cashPromoDepositNone) => async (dispatch, getState) => {
  const state = getState();
  const isProduction = getIsProductionMode();

  try {
    if (state.deal.businessModel === BUSINESS_MODELS.B2C && !cashPromoDepositNone) {
      return toast.error(
        i18n.t("notify.apiDefaultError"),
        {},
        "Business model is B2C"
      );
    }

    const { data: currentDeal } = await api.finalizeOrder(
      state.deal.currentDeal?.token,
      cashPromoDepositNone
    );

    dispatch(setCurrentDealAction(currentDeal));
  } catch (error) {
    if (!isProduction) {
      console.warn(`Couldn't finalize order!\n\n`, error);
    }

    if (
      error.response?.data?.errorCode ===
      REQUEST_ERROR_CODES.LEAD_IS_ALREADY_ORDER
    ) {
      dispatch(setCurrentDealAction(error.response.data.lead));
    }

    throw error;
  }
};

export const initMultiOrderAdminFiles = () => async (dispatch, getState) => {
  const state = getState();

  const { data: currentDeal } = await api.initMultiOrder(
    state.deal.currentDeal?.token
  );

  dispatch(setCurrentDealAction(currentDeal));
};

export const setIsScrappageSelectedAction = isScrappageSelected => async dispatch => {
  dispatch({
    type: DEAL_ACTION_TYPES.SET_SCRAPPAGE,
    data: isScrappageSelected,
  });
};

export const setLOAPriceBreakdownAction = data => ({
  type: DEAL_ACTION_TYPES.SET_LOA_PRICE_BREAKDOWN,
  data,
});

export const setVACPriceBreakdownAction = data => ({
  type: DEAL_ACTION_TYPES.SET_VAC_PRICE_BREAKDOWN,
  data,
});

export const setLLDPriceBreakdownAction = data => ({
  type: DEAL_ACTION_TYPES.SET_LLD_PRICE_BREAKDOWN,
  data,
});

export const setSCFPriceBreakdownAction = data => ({
  type: DEAL_ACTION_TYPES.SET_SCF_PRICE_BREAKDOWN,
  data,
});

export const toggleIsScrappageSelectedAction = () => async (
  dispatch,
  getState
) => {
  const state = getState();
  const { deal } = state;

  dispatch({
    type: DEAL_ACTION_TYPES.TOGGLE_SCRAPPAGE,
  });

  const currentIsScrappageSelected = deal.isScrappageSelected;
  const targetIsScrappageSelected = !currentIsScrappageSelected;

  dispatch(setIsScrappageSelectedAction(targetIsScrappageSelected));

  try {
    await api.updateSession({
      isScrappageSelected: targetIsScrappageSelected,
    });
  } catch (err) {
    dispatch(setIsScrappageSelectedAction(currentIsScrappageSelected));

    toast.error(
      i18n.t("notify.apiDefaultError"),
      {},
      { message: `Couldn't update session!\n\n`, err }
    );
  }

  dispatch(calculatePricesAction());
};

const sessionUpdateRequestCanceller = getRequestCanceller();
const financeSimulationRequestCanceller = getRequestCanceller();
const accessoriesIncreaseRequestCanceller = getRequestCanceller();
const santanderSimulationRequestCanceller = getRequestCanceller();

export const fetchFinanceSimulationAction = (
  options = { withoutPromoCode: false }
) => async (dispatch, getState) => {
  const { deal, appConfig } = getState();
  const { withoutPromoCode } = options;

  const isDeliveryIncluded = getIsDeliveryPriceIncluded(
    Router.pathname,
    deal.lastCta
  );

  const financeGatewayContext = getFinanceGatewayContext(
    appConfig.configuration.country,
    i18n.language,
    deal,
    appConfig,
    { isDeliveryIncluded, withoutPromoCode }
  );

  const selectedJourney = deal.financeSimulation.journey;

  const isLLDJourneySelected = getIsLLDJourneySelected(selectedJourney);
  const isLOAJourneySelected = getIsLOAJourneySelected(selectedJourney);
  const isVACJourneySelected = getIsVACJourneySelected(selectedJourney);

  if (isLLDJourneySelected) {
    dispatch(setIsLLDPriceBreakRequestPendingAction());
  }

  if (isLOAJourneySelected) {
    dispatch(setIsLOAPriceBreakRequestPendingAction());
  }

  if (isVACJourneySelected) {
    dispatch(setIsVACPriceBreakRequestPendingAction());
  }

  try {
    const promises = produce(
      [
        financeSimulationRequestCanceller(cancelSource =>
          api.fetchFinanceSimulation(financeGatewayContext, {
            cancelToken: cancelSource.token,
          })
        ),
      ],
      promisesDraft => {
        if (deal.financeSimulation.financeWidgetParameters) {
          promisesDraft.push(
            sessionUpdateRequestCanceller(cancelSource =>
              api.updateSession(
                {
                  financeWidgetParameters:
                    deal.financeSimulation.financeWidgetParameters,
                },
                { cancelToken: cancelSource.token }
              )
            )
          );
        }
      }
    );

    const [loaRequestResult] = await Promise.all(promises);

    if (loaRequestResult.isRequestCancelled) {
      return;
    }

    const { data } = loaRequestResult;

    if (data.vehicleSimulation.hasError) {
      if (isLLDJourneySelected) {
        dispatch(setIsLLDPriceBreakRequestErrorAction());
      }

      if (isLOAJourneySelected) {
        dispatch(setIsLOAPriceBreakRequestErrorAction());
      }

      if (isVACJourneySelected) {
        dispatch(setIsVACPriceBreakRequestErrorAction());
      }

      return;
    }

    if (isLLDJourneySelected) {
      dispatch(setLLDPriceBreakdownAction(data));

      dispatch(setIsLLDPriceBreakRequestSuccessAction());
    }

    if (isLOAJourneySelected) {
      dispatch(setLOAPriceBreakdownAction(data));

      dispatch(setIsLOAPriceBreakRequestSuccessAction());
    }

    if (isVACJourneySelected) {
      dispatch(setVACPriceBreakdownAction(data));

      dispatch(setIsVACPriceBreakRequestSuccessAction());
    }

    const accessoryIncreaseResults = data?.accessoryIncrease?.results;

    if (accessoryIncreaseResults) {
      dispatch(setFinanceDeliveryPricesAction(accessoryIncreaseResults));
    }
  } catch (error) {
    debug(`❌ Couldn't get '${selectedJourney}' finance simulation\n\n`, error);

    if (isLLDJourneySelected) {
      dispatch(setIsLLDPriceBreakRequestErrorAction());
    }

    if (isLOAJourneySelected) {
      dispatch(setIsLOAPriceBreakRequestErrorAction());
    }

    if (isVACJourneySelected) {
      dispatch(setIsVACPriceBreakRequestErrorAction());
    }
  }
};

// TODO fetch accessoriesIncrease -frontend
export const fetchAccessoriesIncreaseAction = (
  options = { withoutPromoCode: false }
) => async (dispatch, getState) => {
  const { deal, appConfig } = getState();
  const { withoutPromoCode } = options;

  const { configuration } = appConfig;

  const journey =
    configuration.app.solJourney[
      journeyTypeToJourneyName(deal.financeSimulation.journey)
    ];

  if (journey && journey.accessoriesIncrease === false) return;

  const isDeliveryIncluded = getIsDeliveryPriceIncluded(
    Router.pathname,
    deal.lastCta
  );

  const selectedJourney = deal.financeSimulation.journey;

  const financeGatewayContext = getFinanceGatewayContext(
    appConfig.configuration.country,
    i18n.language,
    deal,
    appConfig,
    { isDeliveryIncluded, withAccessories: true, withoutPromoCode }
  );

  try {
    const accessoriesRequestResult = await accessoriesIncreaseRequestCanceller(
      cancelSource =>
        api.fetchAccessoriesIncrease(financeGatewayContext, {
          cancelToken: cancelSource.token,
        })
    );

    if (accessoriesRequestResult.isRequestCancelled) {
      return;
    }

    const { data } = accessoriesRequestResult;

    const accessoryIncreaseResults = data?.accessoryIncrease?.results;

    if (accessoryIncreaseResults) {
      dispatch(setFinanceDeliveryPricesAction(accessoryIncreaseResults));
      dispatch(setFinanceAccessoryIncreaseAction(accessoryIncreaseResults));
    }
  } catch (error) {
    debug(
      `❌ Couldn't get '${selectedJourney}' accessories increase\n\n`,
      error
    );
  }
};

export const fetchSantanderSimulationAction = (
  options = { withoutPromoCode: false }
) => async (dispatch, getState) => {
  const { deal, appConfig } = getState();
  const { withoutPromoCode } = options;

  const isDeliveryIncluded = getIsDeliveryPriceIncluded(
    Router.pathname,
    deal.lastCta
  );

  const santanderContext = getSantanderContext(
    appConfig.configuration.country,
    i18n.language,
    deal,
    appConfig,
    { isDeliveryIncluded, withoutPromoCode }
  );

  const { journey: selectedJourney } = deal.financeSimulation;
  const simulationCode =
    deal.scfPriceBreakdown?.vehicleSimulation?.result?.simulationCode;

  const isSCFJourneySelected = getIsSCFJourneySelected(selectedJourney);

  if (isSCFJourneySelected) {
    dispatch(setIsSCFSimulationRequestPendingAction());
  }

  try {
    const promises = [
      santanderSimulationRequestCanceller(cancelSource =>
        api.fetchSantanderSimulation({ ...santanderContext }, simulationCode, {
          cancelToken: cancelSource,
        })
      ),
    ];

    const [scfRequestResult] = await Promise.all(promises);

    if (scfRequestResult.isRequestCancelled) {
      return;
    }

    const { data } = scfRequestResult;

    if (data.hasError) {
      dispatch(setIsSCFSimulationRequestErrorAction());

      return;
    }

    dispatch(setSCFPriceBreakdownAction(data));

    dispatch(setIsSCFSimulationRequestSuccessAction());
  } catch (error) {
    debug(`❌ Couldn't get '${selectedJourney}' finance simulation\n\n`, error);

    dispatch(setIsSCFSimulationRequestErrorAction());
  }
};

export const refreshDealWithAppConfigAction = () => (dispatch, getState) => {
  const {
    deal: {
      carConfiguration: { preconfiguration: activePreconfiguration },
      userProfile: { deliveryData },
      lastCta,
    },
    appConfig: {
      car: { preconfigurations },
      deliveryMethods,
    },
  } = getState();

  const findById = (id, items) => items.find(item => item.id === id);

  const handleActivePreconfigurationChange = () => {
    const newPreconfiguration = findById(
      activePreconfiguration.id,
      preconfigurations
    );

    if (newPreconfiguration.language !== activePreconfiguration.language) {
      dispatch(updateCarPreconfigurationAction(newPreconfiguration));
    }
  };

  const handleActiveDeliveryChange = () => {
    const newDeliveryMethod = findById(deliveryData?.id, deliveryMethods);

    if (newDeliveryMethod?.language !== deliveryData?.language) {
      dispatch(setDeliveryDataAction(newDeliveryMethod));
    }
  };

  handleActivePreconfigurationChange();
  handleActiveDeliveryChange();
  dispatch(forceUpdateCarConfigurationDataAction());

  if (
    [CTA_SOL_TYPES.REFRESH_OFFER_DATA, CTA_SOL_TYPES.OFFER_CREATED].includes(
      lastCta
    )
  ) {
    dispatch(
      postDealAction(
        CTA_SOL_TYPES.REFRESH_OFFER_DATA,
        DEAL_TYPE.DEAL,
        null,
        false
      )
    );
  }

  dispatch(setLastChangedAtAction(Date.now()));
};

export const setFinanceAccessoryIncreaseAction = data => ({
  type: DEAL_ACTION_TYPES.SET_ACCESSORY_INCREASE,
  data,
});

export const setFinanceDeliveryPricesAction = data => ({
  type: DEAL_ACTION_TYPES.SET_FINANCE_DELIVERY_PRICES,
  data,
});

export const setVehicleQuantityAction = quantity => ({
  type: DEAL_ACTION_TYPES.SET_VEHICLES_QUANTITY,
  data: quantity,
});

export const setBankProviderAction = data => ({
  type: DEAL_ACTION_TYPES.SET_BANK_PROVIDER,
  data,
});

export const setPromoCodeAction = data => ({
  type: DEAL_ACTION_TYPES.SET_PROMO_CODE,
  data,
});

export const removePromoCodeAction = () => ({
  type: DEAL_ACTION_TYPES.REMOVE_PROMO_CODE,
});

export const setPromoCodeInvalidAction = () => ({
  type: DEAL_ACTION_TYPES.SET_PROMO_CODE_INVALID,
});
